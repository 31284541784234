<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Reliability of Experimental Measurements - Report Sheet - Parts B & C</h2>

      <h3 class="mb-3">Part B</h3>

      <p class="mb-5">
        Fill in the table below with data and calculated values using your averaged data for the
        three trials for each solution of NaCl. This data will then be used to construct a
        calibration curve as detailed below.
      </p>

      <v-simple-table class="mb-6">
        <thead>
          <tr>
            <td style="font-weight: bold">Concentration</td>
            <td style="font-weight: bold; text-align: center">Mass (g)</td>
            <td style="font-weight: bold; text-align: center">Volume (mL)</td>
            <td style="font-weight: bold; text-align: center">Density (g/mL)</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="conc in concentrationTrials" :key="conc.wtPct">
            <td>{{ conc.wtPct }}</td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs[conc.mass]" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs[conc.volume]" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[conc.density]"
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        b) Prepare a calibration curve of Density vs Concentration (in wt%) using this data in
        Excel. Include a linear trend line on your graph to help you answer parts c) and e) of this
        question. Please, upload your graph using the input field below.
      </p>

      <p><b>Accepted file types</b>: PDF, JPG, PNG, XLSX, XLS</p>

      <v-row class="mb-5 pl-4">
        <v-file-input v-model="graphUpload" multiple />
      </v-row>

      <p class="mb-2">
        c) What were the slope and intercept of the linear trend line from your graph?
      </p>

      <calculation-input
        v-model="inputs.slopeInput"
        class="mb-2"
        prepend-text="$\text{Slope}:$"
        append-text="$\text{Units not provided}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.interceptInput"
        class="mb-5"
        prepend-text="$\text{y-int}:$"
        append-text="$\text{Units not provided}$"
        :disabled="!allowEditing"
      />

      <p class="mb-6">
        <stemble-latex :content="equation" />
      </p>

      <h3 class="mb-3">Part C</h3>

      <p class="mb-5">
        d) Please use the data collected for your unknown solution of NaCl to fill in the table
        below.
      </p>

      <v-simple-table class="mb-6">
        <thead>
          <tr>
            <td />
            <td style="font-weight: bold; text-align: center">Mass (g)</td>
            <td style="font-weight: bold; text-align: center">Volume (mL)</td>
            <td style="font-weight: bold; text-align: center">Density (g/mL)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trial 1</td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.massUT1" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.volumeUT1" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.densityUT1" outlined :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Trial 2</td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.massUT2" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.volumeUT2" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.densityUT2" outlined :disabled="!allowEditing" />
            </td>
          </tr>
          <tr>
            <td>Trial 3</td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.massUT3" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.volumeUT3" outlined :disabled="!allowEditing" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.densityUT3" outlined :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Average</td>
            <td class="py-2 centered-input">
              <calculation-input outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input v-model="inputs.densityUAvg" outlined :disabled="!allowEditing" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">
        e) Based on the values obtained above, what is the concentration of your unknown solution of
        NaCl?
      </p>

      <calculation-input
        v-model="inputs.unknownConc"
        outlined
        prepend-text="$\text{Concentration:}$"
        append-text="$\text{wt}~\%$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-3">
        f) In the space below, discuss your results and potential sources of error in your
        determination of the concentration (in wt%) of the unknown solution of NaCl.
      </p>

      <s-textarea v-model="inputs.sourcesOfError" outlined class="mb-5" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ReliabilityOfMeasurementsReportSheet2',
  components: {
    STextarea,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mass1: null,
        volume1: null,
        density1: null,

        mass2: null,
        volume2: null,
        density2: null,

        mass3: null,
        volume3: null,
        density3: null,

        mass4: null,
        volume4: null,
        density4: null,

        mass5: null,
        volume5: null,
        density5: null,

        slopeInput: null,
        interceptInput: null,

        massUT1: null,
        volumeUT1: null,
        densityUT1: null,

        massUT2: null,
        volumeUT2: null,
        densityUT2: null,

        massUT3: null,
        volumeUT3: null,
        densityUT3: null,

        densityUAvg: null,
        unknownConc: null,
        sourcesOfError: null,
      },
      concentrationTrials: [
        {
          wtPct: '2 wt%',
          mass: 'mass1',
          volume: 'volume1',
          density: 'density1',
        },
        {
          wtPct: '4 wt%',
          mass: 'mass2',
          volume: 'volume2',
          density: 'density2',
        },
        {
          wtPct: '8 wt%',
          mass: 'mass3',
          volume: 'volume3',
          density: 'density3',
        },
        {
          wtPct: '10 wt%',
          mass: 'mass4',
          volume: 'volume4',
          density: 'density4',
        },
        {
          wtPct: '12 wt%',
          mass: 'mass5',
          volume: 'volume5',
          density: 'density5',
        },
      ],
      graphUpload: [],
    };
  },
  computed: {
    intercept() {
      if (this.inputs.interceptInput < 0) {
        return this.inputs.interceptInput;
      } else {
        return '+' + this.inputs.interceptInput;
      }
    },
    equation() {
      if (this.inputs.slopeInput === null) {
        return '$\\textbf{Density} =$';
      } else if (this.inputs.interceptInput === null) {
        return `$\\textbf{Density}= ${this.inputs.slopeInput}\\, \\times \\, \\text{(wt}\\%\\text{ NaCl)}$`;
      } else {
        return `$\\textbf{Density}= ${this.inputs.slopeInput}\\, \\times \\, \\text{(wt}\\%\\text{ NaCl)} ${this.intercept}$`;
      }
    },
    attachments() {
      return [...this.graphUpload];
    },
  },
};
</script>
<style scoped></style>
